import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const signin = async (email, password, set) => {
  const credentials = {
    email: email.toLocaleLowerCase(),
    password,
  };
  try {
    set({ isLoading: true });
    const response = await api.post("/token/", credentials);
    const { access, refresh, user_id } = response.data;

    if (access && refresh) {
      localStorage.setItem("@Auth:access", access);
      localStorage.setItem("@Auth:refresh", refresh);
      set({ access, refresh });
    }

    set({ user_id });

    return response.data;
  } catch (error) {
    throw errorFormater(error.response);
  } finally {
    set({ isLoading: false });
  }
};
