import { Box, Grid, Typography } from "@mui/material";
import { shallow } from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ButtonContainer,
  CustomForm,
  FormFooter,
  FormGroup,
  Header,
  SignInHeader,
  StyledLink,
} from "./SignInForm.styles";

import { InputController } from "../../../components/BaseInput/InputController";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { LAButton } from "../../../components/LAButton/LAButton";
import useUserStore from "../../../state/user/user-store";
import { useNavigate } from "react-router-dom";
import { errorApiHandler } from "../../../services/errorHandler";

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const SignInForm = () => {
  const navigate = useNavigate();
  const [signin, loadStoreData, isLoading] = useUserStore(
    (state) => [state.signin, state.loadStoreData, state.isLoading],
    shallow
  );

  const handleLogin = async (values) => {
    try {
      const response = await signin(values);
      
      if (response && response.two_factor_auth) {
        navigate("/verify-2fa");
      } else {
        await loadStoreData();
        navigate("/home");
      }
    } catch (error) {
      errorApiHandler(error, navigate);
    }
  };

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(signInFormSchema),
  });

  return (
    <>
      <CustomForm onSubmit={handleSubmit((values) => handleLogin(values))}>
        <SignInHeader>
          <Header variant="h2">Log In</Header>
        </SignInHeader>

        <Box>
          <FormGroup>
            <InputController
              control={control}
              label="Email"
              name="email"
              placeholder="Enter email"
              inputProps={{
                "data-testid": "signin-email-field",
              }}
              onBlur={(e) =>
                setValue("email", e.target.value.trim().replace(/ /g, ""), {
                  shouldValidate: true,
                })
              }
              showErrorMessage
            />
          </FormGroup>

          <FormGroup>
            <InputController
              control={control}
              label="Password"
              name="password"
              placeholder="*******"
              type="password"
              inputProps={{
                "data-testid": "signin-password-field",
              }}
            />
          </FormGroup>

          <FormFooter>
            <ButtonContainer justifyContent="center">
              <LAButton
                loading={isLoading}
                text="Log In"
                type="submit"
                variant="primary"
                disabled={isLoading}
                data-testid="signin-submit"
                fullWidth
              />
            </ButtonContainer>
            <Grid item xs={12} sm={12} md={6}>
              <StyledLink
                to="/reset-password-email"
                data-testid="signin-forgot-password"
              >
                <Typography variant="body4">Forgot password?</Typography>
              </StyledLink>
            </Grid>
          </FormFooter>
        </Box>
      </CustomForm>
    </>
  );
};
