import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export async function loadStoreData(set) {
  const access = localStorage.getItem("@Auth:access");
  const refresh = localStorage.getItem("@Auth:refresh");
  if (access && refresh) {
    try {
      set({ access, refresh, isLoading: true });
      const response = await api.get(`/users/profile/`);
      const { first_name, last_name, id, is_staff, email, show_my_query, two_factor_auth, user_id } =
        response.data;
      set({
        email,
        first_name,
        last_name,
        id,
        is_staff,
        isLoading: false,
        myQuerryOnly: show_my_query,
        two_factor_auth: two_factor_auth || false,
        user_id,
      });
    } catch (error) {
      set({ isLoading: false });
      throw errorFormater(error.response);
    }
  }
}
