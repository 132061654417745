import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignInLayout from "../../Layouts/SignInLayout";
import SignInIcon from "../../components/SignInIcon/SignInIcon";
import { LAButton } from "../../components/LAButton/LAButton";
import { InputController } from "../../components/BaseInput/InputController";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EmailIcon from "../../assets/icons/EmailIcon.svg";
import {
  ButtonContainer,
  CustomForm,
  FormFooter,
  Header,
  InputContainer,
  ResetContent,
  SubText,
} from "../../templates/ResetPasswordEmail/ResetPasswordEmail.styles";
import useUserStore from "../../state/user/user-store";
import { shallow } from "zustand/shallow";
import { toast } from "sonner";
import Toaster from "../../components/Toaster/Toaster";

const TwoFactorVerification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user_id, twoFactorVerify, loadStoreData] = useUserStore(
    (state) => [
      state.user_id, 
      state.twoFactorVerify, 
      state.loadStoreData,
    ],
    shallow
  );

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      verificationCode: "",
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        verificationCode: yup
          .string()
          .required("Verification code is required")
          .matches(/^\d{6}$/, "Must be exactly 6 digits"),
      })
    ),
  });

  const handleVerify = async (values) => {
    setIsLoading(true);
    try {
      if (!user_id) {
        toast.custom((t) => (
          <Toaster
            t={t}
            message={"Authentication error. Please try logging in again."}
            type="error"
          />
        ));
        navigate("/signin");
        return;
      }
      
      await twoFactorVerify({
        code: values.verificationCode,
        user_id,
      });

      await loadStoreData();
      navigate("/home");
    } catch (error) {
      console.error("Verification failed:", error);
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Invalid verification code. Please try again."}
          type="error"
        />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SignInLayout>
      <ResetContent>
        <CustomForm onSubmit={handleSubmit(handleVerify)}>
          <SignInIcon src={EmailIcon} alt="email-icon" />

          <Header variant="h2" align="center">
            Two-Factor Authentication
          </Header>
          <SubText variant="subtitle3" component="p" align="center">
            We've sent a verification code to your email address.
            Please enter the code below to complete your login.
          </SubText>
          <InputContainer>
            <InputController
              control={control}
              label="Verification Code"
              name="verificationCode"
              placeholder="Enter 6-digit code"
              type="text"
              inputProps={{
                "data-testid": "verification-code-field",
                maxLength: 6,
                style: { 
                  height: "auto"
                }
              }}
              showErrorMessage
            />
          </InputContainer>

          <FormFooter>
            <ButtonContainer justifyContent="center">
              <LAButton
                text="Verify"
                type="submit"
                variant="primary"
                disabled={!isValid || isLoading}
                data-testid="verify-code"
                fullWidth
                loading={isLoading}
              />
            </ButtonContainer>
          </FormFooter>
        </CustomForm>
      </ResetContent>
    </SignInLayout>
  );
};

export default TwoFactorVerification; 