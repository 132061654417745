import {
  Container,
  Content,
  Footer,
  Main,
  StyledHeader,
  StyledText,
  InputsContainer,
  RulesBlock,
  RulesHeader,
  Rules,
  RuleContainer,
  Point,
  RulesText,
  ButtonContainer,
  CustomFormContainer,
  StyledLink,
} from "./Settings.styles";
import PagesLayout from "../../Layouts/PagesLayout";
import { useForm } from "react-hook-form";
import { InputController } from "../../components/BaseInput/InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box } from "@mui/material";
import { LAButton } from "../../components/LAButton/LAButton";
import useUserStore from "../../state/user/user-store";
import { shallow } from "zustand/shallow";
import { toast } from "sonner";
import Toaster from "../../components/Toaster/Toaster";
import { useEffect, useState } from "react";
import { errorApiHandler } from "../../services/errorHandler";
import { useNavigate } from "react-router-dom";
import TwoFactorSection from "./TwoFactorSection/TwoFactorSection";

const rules = [
  "Your password can’t be too similar to your other personal information.",
  "Your password must contain at least 8 characters.",
  "Your password can’t be a commonly used password.",
  "Your password can’t be entirely numeric.",
];

const getValidationSchema = (passwordChanged) => {
  if (!passwordChanged) {
    return yup.object().shape({});
  }
  
  return yup.object().shape({
    old_password: yup.string().required("Current password is required"),
    new_password: yup.string().required("New password is required"),
    newPasswordConfirm: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords Do Not Match"),
  });
};

const Settings = () => {
  const navigate = useNavigate();
  const [changePassword, twoFactorAuth, isLoading, two_factor_auth] = useUserStore(
    (state) => [state.changePassword, state.twoFactorAuth, state.isLoading, state.two_factor_auth],
    shallow
  );

  const [twoFactorEnabled, setTwoFactorEnabled] = useState(!!two_factor_auth);
  const [twoFactorChanged, setTwoFactorChanged] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    setTwoFactorEnabled(!!two_factor_auth);
  }, [two_factor_auth]);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    trigger,
    setError,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
      newPasswordConfirm: "",
    },
    mode: "onChange",
    resolver: yupResolver(getValidationSchema(passwordChanged)),
  });
  
  const password = watch("new_password");
  const oldPassword = watch("old_password");
  
  useEffect(() => {
    const hasPasswordValues = !!oldPassword || !!password;
    setPasswordChanged(hasPasswordValues);
  }, [oldPassword, password]);
  
  useEffect(() => {
    if (passwordChanged) {
      trigger("newPasswordConfirm");
    }
  }, [password, trigger, passwordChanged]);

  const handleTwoFactorChange = (newValue) => {
    setTwoFactorEnabled(newValue);
    setTwoFactorChanged(newValue !== !!two_factor_auth);
  };

  const handleUpdateSettings = async (values) => {
    try {
      if (passwordChanged) {
        const passwordData = {
          old_password: values.old_password,
          new_password: values.new_password,
        };
        
        if (twoFactorChanged) {
          passwordData.two_factor_auth = twoFactorEnabled;
        }
        
        await changePassword(passwordData);
        toast.custom((t) => (
          <Toaster
            t={t}
            message={"Your settings have been successfully updated."}
            type="info"
          />
        ));
        reset();
        setTwoFactorChanged(false);
      } 
      else if (twoFactorChanged) {
        await twoFactorAuth({
          two_factor_auth: twoFactorEnabled,
        });
        toast.custom((t) => (
          <Toaster
            t={t}
            message={"Two-factor authentication settings updated."}
            type="info"
          />
        ));
        setTwoFactorChanged(false);
      }
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  const isSaveButtonEnabled = (passwordChanged && isValid) || twoFactorChanged;

  return (
    <PagesLayout>
      <Container>
        <StyledHeader variant="h1">Settings</StyledHeader>
        <form onSubmit={handleSubmit(handleUpdateSettings)}>
          <Content>
            <Main>
              <StyledText variant="subtitle3">Change password</StyledText>
              <InputsContainer>
                <CustomFormContainer>
                  <Box>
                    <InputController
                      control={control}
                      label="Old password*"
                      name="old_password"
                      placeholder="*********"
                      type="password"
                      inputProps={{
                        "data-testid": "current-password-field",
                      }}
                      showErrorMessage={passwordChanged}
                    />
                  </Box>
                  <Box>
                    <InputController
                      control={control}
                      label="New password*"
                      name="new_password"
                      placeholder="*********"
                      type="password"
                      inputProps={{
                        "data-testid": "new-password-field",
                      }}
                      showErrorMessage={passwordChanged}
                    />
                  </Box>
                  <Box>
                    <InputController
                      control={control}
                      label="Confirm new password*"
                      name="newPasswordConfirm"
                      placeholder="*********"
                      type="password"
                      inputProps={{
                        "data-testid": "confirm-password-field",
                      }}
                      showErrorMessage={passwordChanged}
                    />
                  </Box>
                  <TwoFactorSection 
                    twoFactorEnabled={twoFactorEnabled}
                    onToggle={handleTwoFactorChange}
                  />
                </CustomFormContainer>
              </InputsContainer>
              <Box>
                <RulesBlock>
                  <RulesHeader variant="body3" component="p">
                    Password requirements
                  </RulesHeader>
                  <Rules>
                    {rules.map((rule) => (
                      <RuleContainer key={rule}>
                        <Point />
                        <RulesText variant="body8">{rule}</RulesText>
                      </RuleContainer>
                    ))}
                  </Rules>
                </RulesBlock>
              </Box>
            </Main>

            <Footer>
              <ButtonContainer justifyContent="center">
                <StyledLink to="/home">
                  <LAButton
                    text="Cancel"
                    type="button"
                    variant="secondary"
                    disabled={isLoading}
                    data-testid="cancel-settings"
                    fullWidth
                  />
                </StyledLink>
              </ButtonContainer>
              <ButtonContainer justifyContent="center">
                <LAButton
                  text="Save"
                  type="submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={!isSaveButtonEnabled || isLoading}
                  data-testid="confirm-password-change"
                  fullWidth
                />
              </ButtonContainer>
            </Footer>
          </Content>
        </form>
      </Container>
    </PagesLayout>
  );
};

export default Settings;
