import React from "react";
import { Switch, FormControlLabel } from "@mui/material";
import {
  TwoFactorContainer,
  TwoFactorHeader,
  TwoFactorDescription,
} from "../Settings.styles";
import useUserStore from "../../../state/user/user-store";
import { shallow } from "zustand/shallow";

const TwoFactorSection = ({ twoFactorEnabled, onToggle }) => {
  const [isLoading] = useUserStore(
    (state) => [state.isLoading],
    shallow
  );

  const handleToggle = () => {
    onToggle(!twoFactorEnabled);
  };

  return (
    <TwoFactorContainer>
      <TwoFactorHeader variant="h6">Two-Factor Authentication</TwoFactorHeader>
      <TwoFactorDescription variant="body2">
        Add an extra layer of security to your account by requiring a verification code 
        sent to your email when you sign in.
      </TwoFactorDescription>
      
      <FormControlLabel
        control={
          <Switch
            checked={twoFactorEnabled}
            onChange={handleToggle}
            color="primary"
            disabled={isLoading}
          />
        }
        label={twoFactorEnabled ? "Enabled" : "Disabled"}
      />
    </TwoFactorContainer>
  );
};

export default TwoFactorSection; 