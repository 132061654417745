export const userInitialData = {
  access: localStorage.getItem("@Auth:access"),
  refresh: localStorage.getItem("@Auth:refresh"),
  pageBeforeRefresh: window.location.pathname,
  isRedirected: false,
  isLoading: false,
  email: null,
  first_name: null,
  last_name: null,
  id: null,
  is_staff: null,
  myQuerryOnly: false,
  user_id: null,
  two_factor_auth: false,
};

export const emptyData = {
  access: null,
  refresh: null,
  isLoading: false,
  email: null,
  first_name: null,
  last_name: null,
  id: null,
  is_staff: null,
  myQuerryOnly: false,
  two_factor_auth: false,
  user_id: null,
};
