import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const twoFactorVerify = async (data, set) => {
  try {
    set({ isLoading: true });
    const response = await api.post("/users/2fa/verify/", data);
    const { access, refresh } = response.data;
    localStorage.setItem("@Auth:access", access);
    localStorage.setItem("@Auth:refresh", refresh);
    set({ access, refresh });
  } catch (error) {
    console.log(error.response.data);
    throw errorFormater(error.response);
  } finally {
    set({ isLoading: false });
  }
};
