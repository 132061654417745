import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const twoFactorAuth = async (data, set) => {
  try {
    set({ isLoading: true });
    const response = await api.post("/users/2fa/toggle/", data);
    const { two_factor_auth } = response.data;
    set({ two_factor_auth });
  } catch (error) {
    throw errorFormater(error.response);
  } finally {
    set({ isLoading: false });
  }
};
